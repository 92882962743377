<template>
  <div>
    <StoreSwitcher v-if="isAdmin" />
    <CheckoutsFilter v-model="filter" @filter="loadCheckouts" />
    <CheckoutsList
      hide-warnings
      is-report
      :checkouts="checkouts"
      @refresh="loadCheckouts"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import CheckoutsFilter from '@/components/Checkouts/CheckoutsFilter'
import CheckoutsList from '@/components/Checkouts/CheckoutsList'
import { formatDate } from '@/helpers/date'
import StoreSwitcher from '@/components/Stores/StoreSwitcher'

export default {
  name: 'Checkouts',
  components: {
    CheckoutsFilter,
    CheckoutsList,
    StoreSwitcher
  },
  mixins: [
    withAsyncAction({
      key: 'checkouts',
      initialState: [],
      fetcher: {
        methodName: 'fetchCheckouts',
        handler: services.checkouts.fetchCheckouts
      }
    })
  ],
  data() {
    return {
      filter: {
        fromDate: formatDate(new Date()),
        toDate: formatDate(new Date())
      }
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId', 'isAdmin'])
  },
  created() {
    this.loadCheckouts()
  },
  methods: {
    loadCheckouts() {
      const storeId = this.currentStoreId
      const { fromDate, toDate } = this.filter
      this.fetchCheckouts({ storeId, fromDate, toDate })
    }
  }
}
</script>

<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <Checkouts />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import Checkouts from '@/components/Reports/Checkouts'

export default {
  name: 'ReportsCheckoutView',
  components: {
    Layout,
    Checkouts
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Relatórios',
        to: { name: 'ReportsIndex' }
      },
      {
        text: 'Caixas',
        to: { name: 'ReportCheckouts' },
        active: true
      }
    ]
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"md":"5","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data Inicial","label-for":"from-date"}},[_c('b-form-datepicker',{attrs:{"id":"from-date","placeholder":"Selecione uma data","date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },"locale":"pt-BR"},model:{value:(_vm.form.fromDate),callback:function ($$v) {_vm.$set(_vm.form, "fromDate", $$v)},expression:"form.fromDate"}})],1)],1),_c('b-col',{attrs:{"md":"7","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data Final","label-for":"to-date"}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex gap-1"},[_c('b-form-datepicker',{staticClass:"flex-1",attrs:{"id":"to-date","placeholder":"Selecione uma data","date-format-options":{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },"locale":"pt-BR"},model:{value:(_vm.form.toDate),callback:function ($$v) {_vm.$set(_vm.form, "toDate", $$v)},expression:"form.toDate"}}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleFilter}},[_c('span',{staticClass:"fas fa-filter mr-2"}),_vm._v("Filtrar ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.handleClear}},[_c('span',{staticClass:"fas fa-times mr-2"}),_vm._v("Limpar ")])],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
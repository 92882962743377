<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group label="Data Inicial" label-for="from-date">
            <b-form-datepicker
              id="from-date"
              v-model="form.fromDate"
              placeholder="Selecione uma data"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }"
              locale="pt-BR"
            />
          </b-form-group>
        </b-col>
        <b-col md="7" sm="12">
          <b-form-group label="Data Final" label-for="to-date">
            <b-row>
              <b-col>
                <div class="d-flex gap-1">
                  <b-form-datepicker
                    id="to-date"
                    v-model="form.toDate"
                    class="flex-1"
                    placeholder="Selecione uma data"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }"
                    locale="pt-BR"
                  />
                  <b-button variant="primary" @click="handleFilter">
                    <span class="fas fa-filter mr-2" />Filtrar
                  </b-button>
                  <b-button variant="secondary" @click="handleClear">
                    <span class="fas fa-times mr-2" />Limpar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'CheckoutsFilter',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: this.value
    }
  },
  methods: {
    handleInput(value, key) {
      this.form[key] = value
      this.$emit('input', this.form)
    },
    handleFilter() {
      this.$emit('filter')
    }
  }
}
</script>
